<template>
	<a target="_blank" href="https://api.whatsapp.com/send?phone=31703637930">
		<font-awesome-icon icon="fa-brands fa-whatsapp" size="1x" />
	</a>
</template>

<style lang="scss" scoped>
a {
	color: #fff;
	background: #25d366;
	display: flex;
	align-items: center;
	place-content: center center;
	width: 50px;
	height: 50px;
	line-height: 40px;
	text-align: center;
	font-size: 25px;
	border-radius: 50%;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 1000;
	transition: background-color 0.3s;

	svg {
		margin: 0;
	}
}

a:hover {
	background: #128c7e;
}
</style>

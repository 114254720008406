<template>
	<header>
		<nav class="main fix-on-scroll" :class="page && page.headingImage ? '' : 'no-slider'">
			<div class="row">
				<div class="columns column12 flex-row">
					<div v-if="showMenu" class="menu-button button" @click="hideMenu">
						<font-awesome-icon icon="fa-regular fa-times" size="1x" />
						<span>{{ $t('close') }}</span>
					</div>
					<div v-else class="menu-button button" @click="toggleMenu">
						<font-awesome-icon icon="fa-regular fa-bars" size="1x" />
						<span>{{ $t('menu') }}</span>
					</div>
					<button class="logo" aria-label="Back to homepage" @click="handleLogoClick" />
					<div class="nav-buttons">
						<lang-select :items="page.langmenu" />
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon icon="fa-light fa-envelope" size="1x" />
						</a>
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon icon="fa-light fa-phone" size="1x" />
						</a>
						<social-media class="navigation-socials" :socials="socials" :class="{ dark: showMenu }" />
						<book-button class="button button-cta reserve-now right" aria-label="Reserve now">
							<font-awesome-icon class="icon" icon="fa-light fa-calendar-days" size="1x" />
							<span>{{ $t('bookNow') }}</span>
						</book-button>
					</div>
				</div>
			</div>
			<div class="nav-container" :class="{ active: showMenu }">
				<div class="row">
					<ul class="main-nav">
						<li
							v-for="item in topmenu[locale]"
							:key="item.filename"
							:class="{ 'has-dropdown': item.submenu.length }"
						>
							<nuxt-link :exact="item.slug === 'index'" :to="`${item.filename}`" @click="hideMenu">
								{{ item.header }}
							</nuxt-link>
							<submenu
								v-if="item.submenu.length"
								:hide-all="!showMenu"
								:item="item"
								:subitems="item.submenu"
								@hide-menu="hideMenu"
							/>
						</li>
						<li v-if="page" class="lang-nav">
							<div v-for="item in page.langmenu" :key="`${item.filename}-${item.language}`">
								<nuxt-link
									class="flag"
									:class="item.language"
									:to="`${item.filename}`"
									@click="hideMenu"
								>
									<span>{{ $t(item.language) }}</span>
								</nuxt-link>
							</div>
						</li>
					</ul>
					<div class="nav-contact">
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon icon="fa-light fa-phone" size="1x" />
							<span>{{ defaults[locale].website.phone }}</span>
						</a>
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon icon="fa-light fa-envelope" size="1x" />
							<span>{{ defaults[locale].website.email }}</span>
						</a>
						<social-media :socials="socials" />
					</div>
				</div>
			</div>
		</nav>
	</header>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, socials, fetchDefaults } = useDefaults();
const { locale } = useI18n();
const localeRoute = useLocaleRoute();
const route = useRoute();

await fetchPage();
await fetchDefaults();

const { data: topmenu } = await useWebsiteFetch('topmenu');

const showMenu = ref(false);

const toggleMenu = () => {
	showMenu.value = !showMenu.value;
	document.body.classList.add('locked');
};

const hideMenu = () => {
	showMenu.value = false;
	document.body.classList.remove('locked');
};

const handleLogoClick = () => {
	if (showMenu.value) {
		hideMenu();
	}

	if (route.name?.startsWith('index')) {
		window.scrollTo(0, 0);
	} else {
		const route = localeRoute('index');
		navigateTo(route.fullPath);
	}
};
</script>

<style lang="scss" scoped>
.logo {
	all: unset;
	cursor: pointer;
	background: url('~/assets/images/logo-corona-hotel-white-full-B.png') no-repeat center center;
	background-size: 184px;
	width: 184px;
	height: 72px;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	animation: fade-from-top 1s ease;
}

nav.main {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	padding: 30px 0;
	transition: all 0.3s ease-in-out;
	overflow: visible;

	&.scroll,
	&.no-slider {
		padding: 25px;
		background: #fff;
		box-shadow: 0 1px 4px rgba(0 0 0 / 25%);

		.menu-button,
		.nav-buttons a:not(.button),
		.lang-select {
			color: $body-color;
		}

		.logo {
			background: url('~/assets/images/logo-corona-hotel-black-full-B.png') no-repeat center center;
			background-size: 184px;
		}

		.lang-select {
			border-color: #c5b9ad;
		}
	}
}

body.locked {
	nav.main {
		.logo {
			background: url('~/assets/images/logo-corona-hotel-black-full-B.png') no-repeat center center;
			background-size: 184px;
		}

		.menu-button {
			color: $body-color;
		}
	}

	.nav-buttons a:not(.button),
	.lang-select {
		color: $body-color;
	}

	.lang-select {
		border-color: #c5b9ad;
	}
}

nav .row {
	overflow: visible;
	z-index: 102;
	position: relative;
}

.menu-button {
	background: none;
	padding: 0;
	margin: 0;
	font-size: 22px;
	font-weight: 400;
	cursor: pointer;
	text-align: left;
	animation: fade-from-left 0.7s ease;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	opacity: 0.85;

	&:hover {
		opacity: 1;
		background: none;
	}
}

nav .flex-row,
.nav-buttons {
	justify-content: space-between;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.nav-buttons {
	gap: 11px;
	color: #fff;
	animation: fade-from-right 0.7s ease;

	a {
		color: #fff;
		text-decoration: none;
		margin: 0;
		opacity: 0.85;

		svg {
			margin-right: 0;
		}

		&.reserve-now {
			padding: 10px 25px 10px 16px;

			svg {
				margin: 0 11px 0 0;
			}
		}

		&.button,
		&:hover {
			opacity: 1;
		}
	}
}

.reserve-now i {
	display: none;
}

.nav-container {
	background: rgba(255 255 255 / 90%);
	backdrop-filter: blur(17px);
	padding: 30px 50px;
	position: fixed;
	top: 0;
	left: 0;
	padding-top: 80px;
	z-index: 101;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: all 0.4s ease-in-out;
	transform: translate(0, 100%) matrix(1, 0, 0, 1, 0, 0);

	.row {
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		align-items: center;
		height: 100%;
	}
}

.nav-container.active {
	transform: matrix(1, 0, 0, 1, 0, 0);
	opacity: 1;
}

.lang-nav {
	display: none;
	list-style: none;
	text-align: left;
	float: left;
	width: 50%;
	line-height: 26px;
	padding-top: 40px;

	a {
		color: #fff;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-start;
		font-size: 16px !important;
		gap: 5px;

		&.router-link-exact-active,
		&:hover {
			color: $cta-color;
		}
	}

	img {
		max-width: 16px;
		float: left;
		margin: 0 5px 0 0;
	}
}

.lang-select {
	padding: 12px 15px;
	border-right: 1px solid $alternating-background-color;
	cursor: pointer;
	position: relative;
	text-transform: uppercase;
	font-size: 12px;

	svg,
	i {
		font-size: 12px;
	}
}

.lang-wrapper {
	position: absolute;
	top: 35px;
	left: 3px;
	right: 3px;
	padding: 10px 9px;
	text-align: center;
	background: #fff;
	box-shadow: 0 18px 18px rgba(0 0 0 / 10%);
	border-radius: 3px;
	z-index: 90;
}

.lang-wrapper.active,
.lang-wrapper a {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: center;
	color: #000;
	gap: 5px;
}

.lang-wrapper a {
	width: 100%;
	flex-direction: row;
	margin: 2px 0;
}

.lang-wrapper img {
	width: 16px;
	height: 16px;
	margin: 2px 0 0;
}

.mobile-lang-nav {
	margin: 40px 0 0;
	font-size: 14px;
	display: none;
}

.mobile-lang-nav img {
	max-width: 16px;
	margin: 0 10px 0 0;
}

.main-nav .mobile-lang-nav a {
	width: 100%;
	display: block;
	font-size: 16px;
	line-height: 28px;
}

/* Fullscreen Nav */
.main-nav {
	list-style: none;
	min-width: 25%;
	text-align: center;
	padding-top: 80px;

	a {
		color: $header-color;
		font-family: $heading-font-family;
		font-size: 28px;
		line-height: 46px;
		font-weight: 400;

		&:hover,
		&.active {
			color: $cta-color;
		}
	}
}

.nav-contact {
	align-self: flex-end;
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center;
	gap: 20px;
	border-top: 1px solid #d4c6b6;
	padding: 20px 0 0;

	.socials {
		margin: 0;
	}

	> a {
		color: $header-color;
		display: block;
		font-weight: 300;
		margin: 0;

		i {
			margin: 0 10px 0 0;
		}
	}
}

.close-wrapper {
	align-self: flex-start;
}

.nav-close {
	color: #fff;
	cursor: pointer;
	font-size: 54px;
	padding: 0 0 0 12px;
}

.flag::before {
	content: '';
	width: 16px;
	position: relative;
	height: 16px;
	margin: 3px 1px 0 0;
	display: inline-block;
}

.flag.en::before {
	background: url('~/assets/images/en.png') no-repeat;
}

.flag.nl::before {
	background: url('~/assets/images/nl.png') no-repeat;
}

.flag.de::before {
	background: url('~/assets/images/de.png') no-repeat;
}

.flag.fr::before {
	background: url('~/assets/images/fr.png') no-repeat;
}

.flag.es::before {
	background: url('~/assets/images/es.png') no-repeat;
}

@media (max-height: 740px) {
	.nav-container {
		overflow-y: scroll;
	}

	.lang-nav {
		position: relative;
		bottom: auto;
		left: auto;
		z-index: 1;
	}

	.main-nav {
		z-index: 2;
		position: relative;
	}
}

@media (max-height: 700px) {
	.main-nav a {
		font-size: 20px;
		line-height: 36px;
	}
}

@media (max-height: 600px) {
	.nav-contact {
		display: none;
	}
}

@media (max-width: 1040px) {
	.nav-buttons {
		.socials {
			display: none;
		}
	}
}

@media (max-width: 920px) {
	.menu-button {
		justify-content: center;
	}

	.lang-nav {
		display: block;
		width: 100%;
		text-align: center;

		a {
			justify-content: center;
		}
	}

	.close-wrapper {
		align-self: flex-end;
	}

	.nav-buttons .lang-select {
		display: none;
	}

	.nav-buttons {
		a {
			&.reserve-now {
				padding: 0;

				svg {
					margin: 0;
				}
			}
		}
	}
}

@media (max-width: 800px) {
	.lang-nav {
		position: relative;
		bottom: auto;
		left: auto;
		right: auto;
		float: left;
		margin-top: 30px;
	}

	.main-nav {
		a {
			font-size: 22px;
			line-height: 44px;
		}
	}
}

@media (max-width: 600px) {
	.logo {
		background: url('~/assets/images/logo-corona-hotel-white-small-B.png') no-repeat center center;
		background-size: 140px;
		width: 140px;
		height: 55px;
		top: 0;
	}

	nav.main {
		.flex-row {
			padding: 0;
		}

		&.scroll,
		&.no-slider {
			padding: 18px 6px;

			.logo {
				background: url('~/assets/images/logo-corona-hotel-black-small-B.png') no-repeat center center;
				background-size: 140px;
			}
		}
	}

	body.locked {
		nav.main {
			.logo {
				background: url('~/assets/images/logo-corona-hotel-black-small-B.png') no-repeat center center;
				background-size: 140px;
			}
		}
	}

	.nav-container {
		padding: 10px 25px 25px;
	}

	.main-nav,
	.contact-info,
	.lang-nav {
		width: 100%;
	}

	.contact-info {
		margin: 25px auto 0;
	}

	.lang-nav {
		margin-top: 0;
	}

	nav .buttons .button {
		svg {
			margin-right: 0;
			font-size: 16px;
		}

		span {
			display: none;
		}
	}
}
</style>
